import React from "react";
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalOverlay,
  VStack,
  HStack,
  Text,
} from "@chakra-ui/react";
import PastOrders from "../Order/PastOrders";
import { Link } from "react-router-dom";
import Settlements from "../Settlement/Settlements";
import { useLocation } from "react-router-dom";

const MerchantModal = ({ merchant, isOpen, onClose }) => {
  const pathName = useLocation().pathname;
  const isActive = !!(merchant?.name && merchant?.phoneNumber && merchant?.email);
  // const textStyle = {
  //   fontSize: "18px",
  //   fontFamily: "Poppins",
  //   fontWeight: "600",
  // };
  const hStackStyle = {
    width: "100%",
    justifyContent: "space-between",
  };
  return (
    <Modal size={{ base: "",md:"",tablet:"" }} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width={{base:"",md:"",tablet:"1000px"}} borderRadius={"20px"}>
        <ModalCloseButton />
        <ModalBody p={{ base: 4, tablet: 10 }} w="full">
          <VStack gap={{ base: 8, tablet: 16 }} w="full">
            <HStack alignItems={"start"} w="full" gap={10} flexDirection={{base:"column",md:"row",tablet:"row"}}>
              <VStack
                alignItems={"start"}
                w={{base:"100%",tablet:"60%"}}
                borderRight={"1px solid #E9E9E9"}
                pr={10}
              >
                <HStack style={hStackStyle}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Phone Number:</Text>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"}>{merchant?.phoneNumber}</Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Name:</Text>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"}>{merchant?.name}</Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Email ID:</Text>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"}>{merchant?.email}</Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>State & City:</Text>
                  <Text>
                    {merchant?.state}, {merchant?.city}
                  </Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Pin Codes:</Text>
                  <Text>
                  {isActive? merchant?.pinCode: "N/A"}
                  </Text>
                </HStack>
              </VStack>
              <VStack w={"30%"}>
                <HStack style={hStackStyle}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>FSSAI License:</Text>
                  <Link to={merchant?.license}>
                    <Text
                      cursor={"pointer"}
                      color={"scarlet"}
                      textDecor={"underline"}
                    >
                      Click Here
                    </Text>
                  </Link>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>UPI ID:</Text>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"}>{merchant?.upiId}</Text>
                </HStack>
              </VStack>
            </HStack>
            {pathName === "/merchants" ? (
              <PastOrders orders={merchant?.orders?.list} />
            ) : (
              <Settlements merchant={merchant} />
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MerchantModal;
