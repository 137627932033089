import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import {
  HStack,
  VStack,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Box,
} from "@chakra-ui/react";
import Navbar from "../components/Navbar";
import Sidebar from "../components/Sidebar";
import navHeading from "../constants/navHeading";

const Default = () => {
  const pathName = useLocation().pathname;
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <HStack w="100vw" gap={0} bg="wildSand" h="100vh" alignItems="start">
      {/* Sidebar for desktop and tablet view */}
      <HStack display={{ base: "none", md: "flex", tablet: "flex" }}>
        <Sidebar />
      </HStack>

      {/* Mobile hamburger button to open sidebar */}
      <IconButton
        aria-label="Open Sidebar"
        icon={
          <Box as="span" fontSize="24px">
            &#9776;
          </Box>
        } // Unicode for a hamburger icon
        display={{ base: "flex", md:"none",tablet: "none" }}
        onClick={onOpen}
        position="absolute"
        top="1rem"
        left="1rem"
        zIndex={20}
        bg="transparent"
      />

      {/* Sidebar as a drawer for mobile view */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <Sidebar onClose={onClose}/>
        </DrawerContent>
      </Drawer>

      {/* Main content area */}
      <VStack w="full" alignItems="start" h="full">
        <Navbar heading={navHeading[pathName]} />
        <Outlet />
      </VStack>
    </HStack>
  );
};

export default Default;
