import React from "react";
import {
  TableContainer,
  Table,
  Tr,
  Th,
  Thead,
  Tbody,
} from "@chakra-ui/react";
import SettlementTableRow from "./SettlementTableRow";

const SettlementTable = ({ merchant }) => {
  return (
    <TableContainer
      boxShadow={"0px 2px 10px rgba(0,0,0,0.25)"}
      borderRadius={"12px"}
      w="full"
      overflowY={{base:"scroll"}}
      overflowX={{base:"scroll"}}
    >
      <Table variant="simple" size={{ base: "sm", md: "md" }}>
        <Thead bg={"wildSand"} position={"sticky"} top={0} zIndex={1}>
          <Tr>
            <Th fontSize={{base:"10px",tablet:"16px"}}>S.No.</Th>
            <Th fontSize={{base:"10px",tablet:"16px"}}>Cycle</Th>
            <Th fontSize={{base:"10px",tablet:"16px"}}>Details</Th>
          </Tr>
        </Thead>
        <Tbody>
          {merchant?.pastWeeksEarnings?.list?.map((week, index) => {
            return <SettlementTableRow merchant={merchant} week={week} index={index} key={index} />
          })}
        </Tbody>
      </Table>
    </TableContainer>
  );
};

export default SettlementTable;
