import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalOverlay,
  VStack,
  HStack,
  Text,
} from "@chakra-ui/react";
import { axiosInstance } from "../../Axios";
import PastOrders from "../Order/PastOrders";

const AllCustomersModal = ({ user, isOpen, onClose }) => {
  const [orders, setOrders] = useState([]);

  const getHistoryOfOrders = async () => {
    try {
      const response = await axiosInstance.get(`admin/orderHistory/${user.userId}`);
      setOrders(response.data);
    } catch (error) {
      console.error("Error fetching order history", error);
    }
  };

  useEffect(() => {
    if (isOpen && user?.userId) {
      getHistoryOfOrders();
    }
  }, [user, isOpen]);

  // const textStyle = {
  //   fontSize: "18px",
  //   fontFamily: "Poppins",
  //   fontWeight: "600",
  //   lineHeight: "27px",
  // };

  // const textValue = {
  //   fontSize: "18px",
  //   fontFamily: "Poppins",
  //   fontWeight: "400",
  //   lineHeight: "27px",
  // };

  const hStackStyle = {
    width: "100%",
    justifyContent: "space-between",
  };

  // Date formatting function
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };

  return (
    <Modal size={{ base: "",md:"",tablet:"" }} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width={{base:"",md:"",tablet:"1038px"}} height={{base:"",md:"",tablet:"827px"}} borderRadius={"20px"}>
        <ModalCloseButton />
        <ModalBody p={{ base: 4, md:7,tablet: 10 }} w="full">
          <VStack gap={{ base: 5, md:4.5,tablet: 16 }} w="full">
            <HStack
              alignItems={"start"}
              w={{base:"full",md:"full",tablet:"857px"}}
              h={{tablet:"183px"}}
              gap={"52px"}
              flexDirection={{ base: "column", md:"row",tablet: "row" }} // Stack vertically on small screens
            >
              <VStack>
                <HStack style={hStackStyle}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600} lineHeight={{base:"18px",md:"18px",tablet:"27px"}}>Phone Number:</Text>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={400} lineHeight={{base:"18px",md:"18px",tablet:"27px"}}>{user?.phoneNumber}</Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600} lineHeight={{base:"18px",md:"18px",tablet:"27px"}}>Name:</Text>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={400} lineHeight={{base:"18px",md:"18px",tablet:"27px"}}>{user?.name}</Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600} lineHeight={{base:"18px",md:"18px",tablet:"27px"}}>Email ID:</Text>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={400} lineHeight={{base:"18px",md:"18px",tablet:"27px"}}>{user?.email}</Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600} lineHeight={{base:"18px",md:"18px",tablet:"27px"}}>Date of Birth:</Text>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}}>
                    {user?.dob ? formatDate(user.dob) : 'N/A'}
                  </Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600} lineHeight={{base:"18px",md:"18px",tablet:"27px"}}>Gender:</Text>
                  <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={400} lineHeight={{base:"18px",md:"18px",tablet:"27px"}}>{user?.gender}</Text>
                </HStack>
              </VStack>
            </HStack>
            <PastOrders orders={orders} />
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AllCustomersModal;
