import React from "react";
import {
  HStack,
  Icon,
  Text,
  Heading,
  Input,
  Image,
  VStack,
} from "@chakra-ui/react";
import { IoIosSearch } from "react-icons/io";
import { useLocation } from "react-router-dom";

const Navbar = ({ heading }) => {
  const pathName = useLocation().pathname;
  return (
    <HStack
      py={4}
      px={8}
      // ml={"-1px"}
      w="full"
      boxShadow={"0px 2px 8px rgba(0,0,0,0.25)"}
      bg={"#FFF"}
      justifyContent={"space-between"}
      // zIndex={10}
      position="relative"
      paddingLeft={{base:"4rem",tablet:"2rem"}}
    >
      <VStack alignItems={"start"}>
        <Heading fontFamily={"Poppins"} fontSize={{base:"16px",tablet:"24px"}} fontWeight={"600"}>
          {heading}
        </Heading>
        {pathName === "/" && (
          <Text mt={1} fontWeight={"medium"} fontSize={{base:"8px",tablet:"16px"}} fontFamily={"Barlow"}>
            Hi, Samantha. Welcome back to Admin Panel!
          </Text>
        )}
      </VStack>
      <HStack gap={6}>
        {pathName !== "/" && (
          <HStack
            alignItems={"center"}
            pl={{base:3,tablet:6}}
            pr={{base:1,tablet:2}}
            h={{base:"25px",tablet:"50px"}}
            borderRadius={"40px"}
            bg={"catskillWhite"}
          >
            <Icon color={"silverChalice"} w={{base:2,tablet:5}} h={{base:2,tablet:5}} as={IoIosSearch} />
            <Input
              fontFamily={"Inter"}
              ml={{base:0,tablet:2}}
              p={0}
              h={{base:"25px",tablet:"50px"}}
              borderRadius={"40px"}
              color={"poloBlue"}
              fontSize={{base: "10px", tablet:"15px"}}
              border={"none"}
              placeholder="Search for Customer"
            />
          </HStack>
        )}
        <Image src="/images/avatar.png" borderRadius={"50%"} />
      </HStack>
    </HStack>
  );
};

export default Navbar;
