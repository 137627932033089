import React from "react";
import {
  Modal,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalOverlay,
  VStack,
  HStack,
  Text,
  Button,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { axiosInstance } from "../../Axios";

const AccountApprovalModal = ({ user, users, setUsers, isOpen, onClose }) => {
  const approveUser = async () => {
    try {
      const response = await axiosInstance.patch(`admin/approve/${user.id}`);

      if (response.status === 200) {
        setUsers(users.filter((u) => u.id !== user.id));
      }

      onClose();
    } catch (error) {}
  };

  const textStyle = {
    fontSize: "18px",
    fontFamily: "Poppins",
    fontWeight: "600",
    lineHeight: "27px",
  };

  const textValue = {
    fontSize: "18px",
    fontFamily: "Poppins",
    fontWeight: "400",
    lineHeight: "27px",
  };
  const hStackStyle = {
    width: "100%",
    justifyContent: "space-between",
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size={{ base: "full",md:"",tablet:"" }}>
      <ModalOverlay />
      <ModalContent  width={{md:"529px",tablet:"1038px"}} height={{md:"744px",tablet:"827px"}} borderRadius={"20px"}>
        <ModalCloseButton />
        <ModalBody p={{ base: 4, md:7,tablet: 10 }} w="full">
          <VStack gap={{ base: 8, md:12,tablet: 16 }} w="full">
            <HStack
              alignItems={"center"}
              justifyContent={"center"}
              w={{base:"full",md:"",tablet:"857px"}}
              h={{md:"",tablet:"183px"}}
              gap={"52px"}
              flexDirection={{ base: "column", md:"column",tablet: "row" }} // Stack vertically on small screens
            >
              <VStack
                // alignItems={""}
                // justifyContent={"center"}
                w={{base:"full",md:"",tablet:"396px"}}
                h={{md:"",tablet:"183px"}}
                borderRight={{ base: "none", md:"1px solid #E9E9E9",tablet: "1px solid #E9E9E9" }} // Remove border on small screens
                pr={10}
              >
                <HStack style={hStackStyle}>
                  <Text style={textStyle}>Phone Number:</Text>
                  <Text style={textValue}>{user?.phoneNumber}</Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text style={textStyle}>Name:</Text>
                  <Text style={textValue}>{user?.name}</Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text style={textStyle}>Email ID:</Text>
                  <Text style={textValue}>{user?.email}</Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text style={textStyle}>State & City:</Text>
                  <Text style={textValue}>
                    {user?.state}, {user?.city}
                  </Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text style={textStyle}>Pin Codes:</Text>
                  <Text style={textValue}>{user?.pinCodes}</Text>
                </HStack>
              </VStack>
              <VStack justifyContent={"start"} w={{base:"full",md:"full",tablet:"396px"}} h={{tablet:"183px"}}>
                <HStack style={hStackStyle}>
                  <Text style={textStyle}>Categories:</Text>
                  <Text>
                    <Text style={textValue}>
                      {user?.categories?.join(", ")}
                    </Text>
                  </Text>
                </HStack>
                <HStack style={hStackStyle}>
                  <Text style={textStyle}>FSSAI License:</Text>
                  <Link to={user?.license} target="_blank">
                    <Text
                      cursor={"pointer"}
                      color={"scarlet"}
                      textDecor={"underline"}
                      style={textValue}
                    >
                      Click Here
                    </Text>
                  </Link>
                </HStack>
              </VStack>
            </HStack>
            <HStack
              w={{base:"full",md:"",tablet:"857px"}}
              h={{base:"full",md:"",tablet:"183px"}}
              gap={"32px"}
              flexDirection={"column"}
              alignItems={"start"}
            >
              <Text
                fontSize={{ base: "20px", md:"",tablet: "25px" }} // Responsive font size
                lineHeight={"36px"}
                fontWeight={"600"}
                fontFamily={"Poppins"}
              >
                Account Approve
              </Text>

              <HStack alignItems={"center"} gap={"16px"}>
                <Button
                  padding={{base:"10px 20px",tablet:"10px 34px 10px 34px"}}
                  width={"104px"}
                  height={"50px"}
                  gap={{md:"",tablet:"10px"}}
                  borderRadius={"20px"}
                  backgroundColor={"#FFE0E0"}
                  color={"#FF1616"}
                  lineHeight={"30px"}
                  fontWeight={"600"}
                  fontFamily={"Poppins"}
                  onClick={approveUser}
                >
                  Yes
                </Button>
                <Button
                  padding={{base:"10px 20px",tablet:"10px 34px 10px 34px"}}
                  width={"104px"}
                  height={"50px"}
                  gap={{md:"",tablet:"10px"}}
                  borderRadius={"20px"}
                  border={"1px"}
                  borderColor={"#FF1616"}
                  backgroundColor={"transparent"}
                  color={"#FF1616"}
                  lineHeight={"30px"}
                  fontWeight={"600"}
                  fontFamily={"Poppins"}
                  onClick={onClose}
                >
                  No
                </Button>
              </HStack>
            </HStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AccountApprovalModal;
