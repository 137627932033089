import { useState, useEffect, useContext } from "react";
import {
  VStack,
  HStack,
  Select,
} from "@chakra-ui/react";
import OrderTable from "../components/Order/OrderTable"; 
import { axiosInstance } from "../Axios";
import { CityContext } from "../context/CityContext"; 

const Orders = () => {
  const [city, setCity] = useState("");
  const [dateRange, setDateRange] = useState("");
  const [orders, setOrders] = useState([]);
  const { cities } = useContext(CityContext);

  const fetchOrders = async () => {
    try {
      const res = await axiosInstance.get(
        `/admin/orders?city=${city}&dateRange=${dateRange}`
      );
      setOrders(res?.data?.list);
    } catch (error) {
      console.log(error);
    }
  }

  useEffect(() => {
    fetchOrders();
  }, [city, dateRange]); 

  return (
    <VStack
      bg={"#FFF"}
      h="full"
      m={{base:0,tablet:10}}
      borderRadius={"20px"}
      alignItems={"start"}
      p={10}
      w={{ base: "100%", tablet: "1038px" }} // Responsive width
      overflowX="hidden"
    >
      <HStack>
        <Select
          boxShadow={"0px 2px 6px rgba(0,0,0,0.25)"}
          border={"none"}
          w="auto"
          placeholder="Select Time"
          fontWeight={"medium"}
          fontFamily={"Barlow"}
          onChange={(e) => setDateRange(e.target.value)} 
        >
          <option value={"today"}>Today</option>
          <option value={"week"}>Week</option>
          <option value={"month"}>Month</option>
        </Select>
        <Select
          boxShadow={"0px 2px 6px rgba(0,0,0,0.25)"}
          border={"none"}
          w="auto"
          placeholder="Select City"
          fontWeight={"medium"}
          fontFamily={"Barlow"}
          onChange={(e) => setCity(e.target.value)} 
        >
          {cities.length > 0 ? (
                        cities.map((city) => (
                        <option key={city.cityId} value={city.cityName}>
                            {city.cityName}
                        </option>
                    ))
                    ) : (
                        <option disabled>Loading cities...</option>
                    )}
        </Select>
      </HStack>
      <OrderTable orders={orders} />
    </VStack>
  );
};

export default Orders;
