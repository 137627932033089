import { useState,useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  Button,
  VStack,
  HStack,
  Input,
  Text,
} from "@chakra-ui/react";
import { axiosInstance } from "../../Axios";

const SettlementModal = ({ merchant, week, isOpen, onClose }) => {
  const [transactionId, setTransactionId] = useState("");
  const [amountSettled, setAmountSettled] = useState("");
  const [remarks, setRemarks] = useState("");
  const [isSettled, setIsSettled] = useState(false); 

  useEffect(() => {
    if (isOpen && merchant && week) {
      const existingSettlement = merchant.settlements.list?.find(
        (settlement) => settlement.week === week.id
      );

      if (existingSettlement) {
        setTransactionId(existingSettlement.transactionId || "");
        setAmountSettled(existingSettlement.amount || "");
        setRemarks(existingSettlement.remarks || "");
        setIsSettled(true); 
      } else {
        setTransactionId("");
        setAmountSettled("");
        setRemarks("");
        setIsSettled(false); 
      }
    }
  }, [isOpen, merchant, week]);

  const handleDone = async () => {
    if(isSettled){
      if(parseFloat(amountSettled)>(week?.totalEarning)){
        alert("Amount settled cannot be greater than net due");
        return;
      }
      await axiosInstance.patch("admin/settlement",{
        weekId: week.id,
        transactionId: transactionId,
        amount: parseFloat(amountSettled),
        remarks: remarks,
        userId: merchant?.userId,
      })
      .then((res)=>{
        console.log(res?.data?.message); 
        onClose();
      }).catch((error)=> console.log(error.message));
    }else{
      if(parseFloat(amountSettled)>(week?.totalEarning)){
        alert("Amount settled cannot be greater than net due");
        return;
      }
      await axiosInstance
      .post("/admin/settlement", {
        weekId: week.id,
        transactionId: transactionId,
        amount: parseFloat(amountSettled),
        remarks: remarks,
        userId: merchant?.userId,
      })
      .then((res) => {
        console.log(res?.data?.message);
        setIsSettled(true); 
        onClose();
      })
      .catch((error) => console.log(error.message));
    }
    
  };

  // const textStyle = {
  //   fontSize: "18px",
  //   fontFamily: "Poppins",
  //   fontWeight: "600",
  // };
  const hStackStyle = {
    width: "100%",
    justifyContent: "space-between",
  };

  return (
    <Modal size={{ base: "",md:"",tablet:"" }} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent width={{base:"",md:"",tablet:"1000px"}} borderRadius={"20px"}>
        <ModalCloseButton />
        <ModalBody p={{ base: 4, md: 10 }} w="full">
          <VStack gap={{ base: 8, md: 16 }} w="full" alignItems={"start"}>
            <VStack alignItems={"start"} w={{base:"100%",md:"100%",tablet:"40%"}} >
              <HStack style={hStackStyle}>
                <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Merchant Name:</Text>
                <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"}>{merchant?.name}</Text>
              </HStack>
              <HStack style={hStackStyle}>
                <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Cycle:</Text>
                <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"}>{week?.duration}</Text>
              </HStack>
              <HStack style={hStackStyle}>
                <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Net Due:</Text>
                <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"}>₹ {(week?.totalEarning).toFixed(2)}</Text>
              </HStack>
            </VStack>
            <VStack alignItems={"start"} w={{base:"100%",md:"75%",tablet:"50%"}}>
              <HStack style={hStackStyle}>
                <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Transaction ID:</Text>
                <Input
                  placeholder="Enter Transction ID"
                  type="text"
                  borderRadius={"40px"}
                  bg={"catskillWhite"}
                  color={"poloBlue"}
                  w={{base:"200px",md:"255px"}}
                  value={transactionId}
                  onChange={(e) => setTransactionId(e.target.value)}
                />
              </HStack>
              <HStack style={hStackStyle}>
                <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Amount Settled:</Text>
                <Input
                  placeholder="Enter Amount"
                  type="text"
                  borderRadius={"40px"}
                  bg={"catskillWhite"}
                  color={"poloBlue"}
                  w={{base:"200px",md:"255px"}}
                  value={amountSettled}
                  onChange={(e) => setAmountSettled(e.target.value)}
                />
              </HStack>
              <HStack style={hStackStyle}>
                <Text fontSize={{base:"12px",md:"12px",tablet:"18px"}} fontFamily={"Poppins"} fontWeight={600}>Remarks:</Text>
                <Input
                  placeholder="Enter Remark"
                  type="text"
                  borderRadius={"40px"}
                  bg={"catskillWhite"}
                  color={"poloBlue"}
                  w={{base:"200px",md:"255px"}}
                  value={remarks}
                  onChange={(e) => setRemarks(e.target.value)}
                />
              </HStack>
            </VStack>
            <VStack w="full" mt={20}>
              <Button
                bg={"scarlet"}
                color={"#FFF"}
                borderRadius={"20px"}
                w={{base:"90px",md:"113px"}}
                onClick={handleDone}
              >
                Done
              </Button>
            </VStack>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default SettlementModal;
