import { useState, useContext } from "react";
import { VStack, HStack, Heading, Text, Input, Button } from "@chakra-ui/react";
import { axiosInstanceWithoutToken } from "../Axios";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";

const LogIn = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const { login } = useContext(AuthContext);
  
  const handleLogIn = async () => {
    try {
      const res = await axiosInstanceWithoutToken.post("admin/login", {
        email: email,
        password: password,
      });
      login(res.data.token);
      navigate("/");
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <HStack h="100vh" w="100vw" flexDirection={{ base: "column", tablet: "row" }}>
      {/* Left Section: Image and Welcome Message */}
      <VStack
        h={{ base: "40%", tablet: "full" }}
        w={{ base: "100%", tablet: "50%" }}
        justifyContent={"center"}
        pos={"relative"}
      >
        <VStack
          _after={{
            content: '""',
            bg: "rgba(255, 22, 22, 0.5)",
            opacity: 0.5,
            pos: "absolute",
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
          }}
          bgRepeat={"no-repeat"}
          bgPos={"center"}
          bgSize={"cover"}
          bgImage={"/images/logIn-banner.png"}
          w="full"
          h="full"
        ></VStack>
        <VStack
          position={"absolute"}
          alignItems={"start"}
          px={{ base: 4, tablet: 10 }}
        >
          <Heading
            fontFamily={"Inter"}
            fontWeight={"600"}
            fontSize={{ base: "24px", tablet: "48px" }}
            color={"#FFF"}
          >
            Welcome Back!
          </Heading>
          <Text
            fontFamily={"Inter"}
            fontWeight={"400"}
            color={"#FFF"}
            fontSize={{ base: "12px", tablet: "16px" }}
          >
            Log in if you already have an account!
          </Text>
        </VStack>
      </VStack>

      {/* Right Section: Login Form */}
      <VStack justifyContent={"center"} w={{ base: "100%", tablet: "50%" }} h={{ base: "60%", tablet: "full" }} p={{ base: 4, tablet: 0 }}>
        <VStack alignItems={"start"} w={{ base: "90%", tablet: "50%" }} maxW={"400px"}>
          <Heading
            fontFamily={"Inter"}
            fontWeight={"600"}
            fontSize={{ base: "24px", tablet: "32px" }}
          >
            Welcome Back!
          </Heading>
          <VStack mt={4} w="full">
            <Input
              borderRadius={"10px"}
              placeholder="Email ID"
              type="email"
              onChange={(e) => setEmail(e.target.value)}
              size={{ base: "sm", tablet: "tablet" }} 
            />
            <Input
              borderRadius={"10px"}
              placeholder="Password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              size={{ base: "sm", tablet: "tablet" }} 
            />
          </VStack>
          <Button
            borderRadius={"10px"}
            w="full"
            onClick={handleLogIn}
            color={"#FFF"}
            bg={"scarlet"}
            mt={2}
            size={{ base: "sm", tablet: "tablet" }}
          >
            Log In
          </Button>
        </VStack>
        <HStack mt={4}>
          <Text fontSize={{ base: "12px", tablet: "16px" }}>
            Don't have an account?
          </Text>
          <Link to={"/signup"}>
            <Text
              textDecor={"underline"}
              color={"dodgerBlue"}
              fontSize={{ base: "12px", tablet: "16px" }}
            >
              Sign Up
            </Text>
          </Link>
        </HStack>
      </VStack>
    </HStack>
  );
};

export default LogIn;
